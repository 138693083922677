<template>
  <v-container class="fill-height w-100 px-0 liShare" fluid>
    <v-row class="mx-md-0 px-2 px-md-0">
      <!-- CONTENT -->
      <template v-if="pageContent != null">
        <!-- SOURCE -->
        <div ref="printMe" class="liNameSource" :style="`background:url(${bgImageShare}); background-size: contain;`">
          <div style="position: relative; width: 100%; height: 100%">
            <!-- CAMPAIGN LOGO -->
            <img :src="campaignLogo" class="liCampaignLogoShare" />
            <!-- BANNER -->
            <img :src="campaignBanner" class="liCampaignBanner" />
            <h1 style="" class="liNameOverlay">{{ name.name }}</h1>
          </div>
        </div>
        <!-- SOURCE2 -->
        <div ref="printMe2" class="liNameSource2" :style="`background:url(${bgImageShareMoBile}); background-size: contain;`">
          <div style="position: relative; width: 100%; height: 100%">
            <!-- CAMPAIGN LOGO -->
            <img :src="campaignLogo" class="liCampaignLogoShare2" />
            <!-- BANNER -->
            <img :src="campaignBanner" class="liCampaignBanner2" />
            <h1 style="" class="liNameOverlay2">{{ name.name }}</h1>
          </div>
        </div>
      </template>
      <!-- OUTPUT -->
      <template v-if="output != null && output2 != null">
        <v-img :src="`${apiPublicUrl}${pageContent.content.hero.image}`" :position="pageContent.content.hero.imagePosition" min-height="100%" height="100%" class="hero blur" aspect-ratio="10,1">
          <div class="liDoneImage">
            <img :src="output2" style="width: 100%" />
          </div>
          <v-sheet
            class="text-left px-5 pb-5 px-md-10 px-lg-15 pb-15 liContentLeft"
            style="overflow: hidden"
            height="100%"
            elevation="20"
            :class="{
              'bg-black': 'step' in pageContent.content && pageContent.content.step != 4,
              'bg-transparent': 'step' in pageContent.content && pageContent.content.step == 4,
            }"
            :min-width="`${'step' in pageContent.content && pageContent.content.step == 4 ? '100%' : isAppMobile ? '100%' : isAppTablet ? '75%' : '50%'}`"
            :max-width="`${'step' in pageContent.content && pageContent.content.step == 4 ? '100%' : isAppMobile ? '100%' : isAppTablet ? '75%' : '50%'}`"
            :min-height="`calc(100vh - ${isAppMobile || isAppTablet ? '50px' : '64px'})`"
            :style="`
                margin-top:${isAppMobile || isAppTablet ? '50px' : '64px'};
            `"
          >
            <div class="inner">
              <!-- TITLE -->
              <h1 class="title text-lg-h3 text-md-h4 text-sm-h5 text-h5 text-uppercase mb-2 font-weight-bold white--text liTextShadow no-hyphens" v-html="pageContent.content.hero.title" />
              <!-- SUBTITLE -->
              <div v-if="'subtitle' in pageContent.content.hero && pageContent.content.hero.subtitle != ''" class="text-p white--text liTextShadow" v-html="pageContent.content.hero.subtitle" />
              <v-btn large class="px-5 mx-auto mt-5 liFacebookBackground" dark :style="isAppMobile || isAppTablet ? 'width: 100%;' : 'width: 90%;'" @click="shareLink('fb')">
                <v-icon color="white" class="mr-3 mb-1"> mdi-facebook </v-icon>
                {{ pageContent.content.hero.shareOnFacebook }}
              </v-btn>
              <br />
              <v-btn
                large
                class="px-5 mx-auto mt-5 liInstagramBackground"
                dark
                download
                :style="isAppMobile || isAppTablet ? 'width: 100%;' : 'width: 90%;'"
                :href="`${apiPublicUrl}/fylwith_pmelegend_2022/${form.fileNameMobile}`"
              >
                <v-icon color="white" class="mr-3 mb-1"> mdi-instagram </v-icon>
                {{ pageContent.content.hero.shareOnInstagram }}
              </v-btn>
              <br />
              <v-btn class="px-5 mx-auto mt-5" dark download :href="`${tenc[language]}`" text :style="isAppMobile || isAppTablet ? 'width: 100%;' : 'width: 90%;'">
                <v-icon color="white" class="mr-3 mb-1"> mdi-download </v-icon>
                {{ pageContent.content.hero.downloadTC }}
              </v-btn>
            </div>
          </v-sheet>
        </v-img>
      </template>
      <template v-else>
        <div class="text-center w-100 d-flex" style="height: 50vh">
          <v-progress-circular indeterminate color="primary" class="m-auto" />
        </div>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import html2canvas from "html2canvas";
import sharebg from "../assets/images/share/FB-Share-image.jpg";
import sharebgMAIL from "../assets/images/share/MAIL-Share-image.jpg";
import campaignLogo from "../assets/images/share/PME-Flight-Experience-LOGO.png";
import campaignBanner from "../assets/images/share/PME-Flight-Experience-banner.png";
const tencEN = "/assets/downloads/PMELEGEND-Norway-winactie-Terms-EN.pdf";
const tencFR = "/assets/downloads/PMELEGEND-Norway-winactie-Terms-BE_FR.pdf";
const tencDE = "/assets/downloads/PMELEGEND-Norway-winactie-Teilnahmebedingungen-DE.pdf";
const tencCH = "/assets/downloads/PMELEGEND-Norway-winactie-Teilnahmebedingungen-CH.pdf";
const tencAT = "/assets/downloads/PMELEGEND-Norway-winactie-Teilnahmebedingungen-DE.pdf";
const tencNL = "/assets/downloads/PMELEGEND-Norway-winactie-Algemene-Voorwaarden-NL.pdf";
const tencBE = "/assets/downloads/PMELEGEND-Norway-winactie-Algemene-Voorwaarden-BE.pdf";
const API_URL = process.env.VUE_APP_CONTENT_API_URL;

export default {
  title: "Share",
  name: "Share",
  data() {
    return {
      apiPublicUrl: API_URL,
      file: null,
      output: null,
      started: false,
      fileupload: false,
      file2: null,
      output2: null,
      started2: false,
      fileupload2: false,
      bgImageShare: sharebg,
      bgImageShareMoBile: sharebgMAIL,
      campaignLogo: campaignLogo,
      campaignBanner: campaignBanner,
      tenc: {
        nl: tencNL,
        be: tencBE,
        be_fr: tencFR,
        de: tencDE,
        ch: tencCH,
        at: tencAT,
        en: tencEN,
      },
    };
  },
  created() {
    this.fetchURL();
  },
  watch: {
    pageContent(val) {
      if (val != null) {
        this.$title = `${decodeURI(this.$route.params.name)} - ${this.pageContent.default.pageTitle}`;
        if (this.started == false) {
          this.started = true;
          setTimeout(() => {
            this.genreateImage();
          }, 1000);
        }
      }
    },
    file(val) {
      if (val != null) {
        if (this.dev == false) {
          // LIVE

          let formData = new FormData();
          formData.append("file", this.file);
          this.$store.dispatch("liApiStore/POST_IMAGE", formData).then((response) => {
            if (response.status == 201) {
              this.fileupload = true;
            } else {
              this.fileupload = false;
            }
          });
        } else {
          // DEV
          console.log(val);
        }
      }
    },
    file2(val) {
      if (val != null) {
        if (this.dev == false) {
          // LIVE
          let formData = new FormData();
          formData.append("file", this.file2);
          this.$store.dispatch("liApiStore/POST_IMAGE", formData).then((response) => {
            if (response.status == 201) {
              this.fileupload = true;
            } else {
              this.fileupload = false;
            }
          });
        } else {
          // DEV
          console.log(val);
        }
      }
    },
  },
  mounted() {
    if (this.dev) {
      console.log("VIEW SHARE:    ", this.name);
      console.log("Language:  ", this.language);
      console.log("Website:   ", this.website);
      console.log("FullUrl:   ", this.fullUrl);
      console.log("MOUNTED     √");
      console.log("NAME        :", this.form.applicantName);
    }
    if (this.form.applicantName == "" && this.form.fileNameMobile == "" && this.form.fileNamePlain == "" && this.form.fileName == "" && this.form.timestamp == "") {
      if (this.dev == false) {
        // LIVE
        this.$router.push(`/${this.language}/404`);
      } else {
        // DEV
        this.fetchPageDataShare();
      }
    } else {
      this.fetchPageDataShare();
    }
  },
  computed: {
    form: {
      get() {
        return this.$store.getters["liApiContent/getForm"];
      },
      set(val) {
        console.log(val);
      },
    },
    name() {
      if (!this.dev) {
        // LIVE
        return {
          name: decodeURI(this.form.applicantName),
          fileName: this.form.fileName,
          fileName2: this.form.fileNameMobile,
          fileNamePlain: this.form.fileNamePlain,
          share: `?id=${this.form.fileNamePlain}&name=${this.form.applicantName}`,
        };
      } else {
        // DEV
        return {
          name: "Dennis van Tol",
        };
      }
    },
    page() {
      // IF THE PAGE IS AN ACTUAL PAGE
      // return this.$store.getters["liApiContent/getPage"](this.fullUrl);
      // IF THE PAGE IS A STATIC BLOCK
      return this.$store.getters["liApiContent/getCollection"](`page_data_${this.language}_share`);
    },
    pageContent() {
      // IF THE PAGE IS AN ACTUAL PAGE
      // return this.page != null ? JSON.parse(this.page.content) : null;
      // IF THE PAGE IS A STATIC BLOCK
      let result = null;
      if (this.page != null) {
        let block = JSON.parse(this.page[0].content);
        if (block.type === "html") {
          result = JSON.parse(block.html);
        }
      }
      return result;
    },
  },
  methods: {
    shareLink() {
      this.share("fb", `https://flywith.pme-legend.com/sharing/index.php${encodeURI(this.name.share)}`);
    },
    genreateImage() {
      const el = this.$refs.printMe;
      const el2 = this.$refs.printMe2;
      const options = {
        type: "dataURL",
      };
      html2canvas(el, options).then((result) => {
        this.output = result.toDataURL("image/jpeg", 100);
        this.started = false;
        this.file = this.dataURLtoFile(this.output, this.name.fileName);
        html2canvas(el2, options).then((result) => {
          this.output2 = result.toDataURL("image/jpeg", 100);
          this.started2 = false;
          this.file2 = this.dataURLtoFile(this.output2, this.name.fileName2);
        });
      });
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
  },
};
</script>
