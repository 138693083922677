<template>
  <v-container
    class="w-100 px-0 liSteps"
    fluid
  >
    <!-- 
    <pre style="position:fixed; right:0;  top: 70; width: 300px; background-color:white; z-index:90000;" >
      {{this.form}}    
    </pre>  
    -->
    <!-- CONTENT -->
    <template v-if="pageContent != null && defaultTextContent != null">
      <v-row class="mx-md-0 px-2 px-md-0">
        
        <!-- STEPS-->
        <aside
          class="liStepCounter vw-100 mt-3"
          v-if="'step' in  pageContent.content"
        >
          <template v-for="index in parseInt(pageContent.content.totalSteps)">
            <div
              v-if="index < pageContent.content.step"
              :key="`step${index}`"
              class="active step"
              style="cursor:pointer;"
              @click.prevent="$router.push(`/${language}/steps/${index}`)"
            >
              <p>
                {{index}}
              </p>
            </div>
            <div
              v-else-if="index == pageContent.content.step"
              :key="`step${index}`"
              class="active step"
            >
              <p>
                {{index}}
              </p>
            </div>
            <div
              v-else
              :key="`step${index}`"
              class="step"
            >
              <p>
                {{index}}
              </p>
            </div>
            <div
              :key="`bar${index}`"
              v-if=" index != parseInt(pageContent.content.totalSteps)"
              :class="{ active: index <= pageContent.content.step }"
              class="bar"
            />
          </template>
        </aside>
        <!-- END STEPS-->

        <!-- MOBILE HEADER -->
        <template v-if="isAppMobile">

          <v-img
            :src="`${apiPublicUrl}${pageContent.content.hero.imageMobile}`"
            :position="pageContent.content.hero.imagePositionMobile"
            width="100vw"
            height="325px"
            :style="`
                margin-top:${isAppMobile || isAppTablet? '50px' :'64px'};
                padding-top:${isAppMobile || isAppTablet? '170px' :'170px'};
            `"
            class="px-2"
          >
            <!-- CAMPAIGN LOGO -->
            <img
              :src="defaultTextContent.campaignLogo"
              class="liCampaignLogo mobile"
            />
            <!-- END CAMPAING LOGO -->
            <!-- TITLE -->
            <h1
              class="
                    text-h4
                    text-uppercase
                    mb-2
                    font-weight-bold
                    white--text
                    liTextShadow
                    liStepTitleMobile
                    no-hyphens
                  "
              v-html="pageContent.content.hero.title"
            />
          </v-img>
        </template>
        <!-- END MOBILE HEADER -->

        <v-img
          :src="`${apiPublicUrl}${pageContent.content.hero.image}`"
          :position="pageContent.content.hero.imagePosition"
          class="hero blur"
          min-height="100vh"
          :class="`liStepNo${pageContent.content.step}`"
        >
         
          <!-- LOADING -->
          <v-overlay v-if="formLoading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-overlay>
          <!-- CAMPAIGN LOGO -->
          <img
            v-if="!isAppMobile"
            :src="defaultTextContent.campaignLogo"
            class="liCampaignLogo"
          />
          <!-- END CAMPAING LOGO -->

          <v-sheet
            v-if="'form' in pageContent.content"
            class="text-left px-5 pb-5 px-md-10 px-lg-15 pb-15 d-flex liContentLeft"
            style="overflow: hidden; "
            elevation="20"
            :class="{
              'bg-black': isAppMobile || 'step' in  pageContent.content && pageContent.content.step != 4 ,
              'bg-transparent':  !isAppMobile && 'step' in  pageContent.content && pageContent.content.step == 4
            }"
            :min-width="`${'step' in  pageContent.content && pageContent.content.step == 4? '100%': isAppMobile ? '100%' :  isAppTablet ? '75%': '50%'}`"
            :max-width="`${'step' in  pageContent.content && pageContent.content.step == 4? '100%': isAppMobile ? '100%' :  isAppTablet ? '75%': '50%'}`"
            :style="`
                margin-top:${isAppMobile || isAppTablet? '0' :'64px'};
            `"
            :min-height="`calc(100% - ${isAppMobile || isAppTablet? '50px' :'64px'})`"
            height="100%"
          >
            <div class="inner">
              
              <!-- DESKTOP -->
              <template v-if="!isAppMobile">
                <!-- TITLE -->
                <h1
                  class="
                    title 
                    text-lg-h3 text-md-h4 text-sm-h5 text-h5 
                    text-uppercase
                    mb-2
                    font-weight-bold
                    white--text
                    liTextShadow
                    liStepTitle
                  "
                  v-html="pageContent.content.hero.title"
                />
                <!-- SUBTITLE -->
                <div
                  v-if="'subtitle' in pageContent.content.hero && pageContent.content.hero.subtitle != ''"
                  class="text-p white--text liTextShadow liStepSubTitle"
                  v-html="pageContent.content.hero.subtitle"
                />
              </template>
              <!-- MOBILE -->
              <template v-else>
                <!-- SUBTITLE -->
                <div
                  v-if="'subtitle' in pageContent.content.hero && pageContent.content.hero.subtitle != ''"
                  class="text-p white--text liTextShadow liStepSubTitle "
                  v-html="pageContent.content.hero.subtitle"
                />
              </template>

              <!-- STEPS -->
              <v-sheet
                class="pa-0 liForm mt-4"
                color="transparent"
                style="overflow: hidden;"
              >

                <!-- STEP 1 -->
                <v-form
                  v-if="'step' in  pageContent.content && pageContent.content.step == 1"
                  ref="form1"
                  v-model="step1valid"
                  
                >

                  <!-- QUESTIONS -->
                  <span class="d-block w-100">
                    <!-- PERSON -->
                    <v-text-field
                      filled
                      dark
                      validate-on-blur
                      v-model="form.firstName"
                      :rules="defaultRules"
                      :label="`${pageContent.content.form.firstName.label}`"
                      class="d-inline-block "
                      :style="`
                        margin-right:${isAppMobile || isAppTablet? '0' :'15px'};
                        width:${isAppMobile || isAppTablet? '100%' :'calc(50% - 7.5px)'};
                
                      `"
                      required
                    />
                    <v-text-field
                      filled
                      dark
                      validate-on-blur
                      v-model="form.lastName"
                      :rules="defaultRules"
                      :label="`${pageContent.content.form.lastName.label}`"
                      required
                      class="d-inline-block"
                      :style="`
                        width:${isAppMobile || isAppTablet? '100%' :'calc(50% - 7.5px)'};
                
                      `"
                    />
                    <!-- ADDRESS -->
                    <v-text-field
                      filled
                      dark
                      validate-on-blur
                      v-model="form.address.streetUser"
                      :rules="defaultRules"
                      :label="`${pageContent.content.form.address.street.label}`"
                      required
                      class="d-inline-block"
                      :style="`
                        width:${isAppMobile || isAppTablet? '100%' :'calc(50% - 7.5px)'};
                      `"
                      style="margin-right:15px;"
                    />
                    <v-text-field
                      filled
                      dark
                      validate-on-blur
                      v-model="form.address.numberUser"
                      :rules="defaultRules"
                      :label="`${pageContent.content.form.address.number.label}`"
                      required
                      class="d-inline-block"
                      :style="`
                        width:${isAppMobile || isAppTablet? 'calc(50% - 7.5px)' :'calc(25% - 7.5px)'}; 
                         margin-right:15px;
                      `"
                    />
                    <v-text-field
                      filled
                      dark
                      validate-on-blur
                      v-model="form.address.additionUser"
                      :label="`${pageContent.content.form.address.addition.label}`"
                      class="d-inline-block"
                       :style="`
                        width:${isAppMobile || isAppTablet? 'calc(50% - 7.5px)' :'calc(25% - 15px)'};
                      `"
                    />
                    <v-text-field
                      filled
                      dark
                      validate-on-blur
                      v-model="form.address.zipUser"
                      :rules="defaultRules"
                      :label="`${pageContent.content.form.address.zip.label}`"
                      required
                      class="d-inline-block"
                      style="width:calc(50% - 7.5px); margin-right:15px;"
                    />
                    <v-text-field
                      filled
                      dark
                      validate-on-blur
                      v-model="form.address.cityUser"
                      :rules="defaultRules"
                      :label="`${pageContent.content.form.address.city.label}`"
                      required
                      class="d-inline-block"
                      style="width:calc(50% - 7.5px);"
                    />
                    <!-- <v-text-field
                      filled
                      dark
                      validate-on-blur
                      v-model="form.address.countryUser"
                      :rules="defaultRules"
                      :label="`${pageContent.content.form.address.country.label}`"
                      required
                      class="d-inline-block"
                      :style="`
                        margin-right:${isAppMobile || isAppTablet? '0' :'15px'};
                        width:${isAppMobile || isAppTablet? '100%' :'calc(50% - 7.5px)'};
                      `"
                    /> -->
                    <v-select
                      :items="defaultTextContent.userCountries"
                      item-value="name"
                      item-text="name"
                      filled
                      dark
                      validate-on-blur
                      v-model="form.address.countryUser"
                      :rules="defaultRules"
                      :label="`${pageContent.content.form.address.country.label}`"
                      required
                      class="d-inline-block"
                      :style="`
                        margin-right:${isAppMobile || isAppTablet? '0' :'15px'};
                        width:${isAppMobile || isAppTablet? '100%' :'calc(50% - 7.5px)'};
                      `"
                    ></v-select>
                    <v-text-field
                      filled
                      dark
                      validate-on-blur
                      v-mask="'##-##-####'"
                      v-model="form.dob"
                      :rules="dobRules"
                      :label="`${pageContent.content.form.dob.label}`"
                      required
                      class="d-inline-block"
                      :style="`
                        width:${isAppMobile || isAppTablet? '100%' :'calc(50% - 7.5px)'};
                      `"
                    />
                     <v-text-field
                      v-model="form.email"
                      :rules="emailRules"
                      :label="`${pageContent.content.form.email.label}`"
                      filled
                      validate-on-blur
                      class="d-inline-block"
                      dark
                      :style="`
                        margin-right:${isAppMobile || isAppTablet? '0' :'15px'};
                        width:${isAppMobile || isAppTablet? '100%' :'calc(50% - 7.5px)'};
                      `"
                      required
                    />
                    <v-text-field
                      filled
                      dark
                      validate-on-blur
                      v-model="form.phone"
                      :label="`${pageContent.content.form.phone.label}`"
                      class="d-inline-block"
                      :style="`
                        width:${isAppMobile || isAppTablet? '100%' :'calc(50% - 7.5px)'};
                      `"
                    />
                  </span>
                  <!-- TERMS & NEXT -->
                  <span class="d-block w-100 text-white">

                    <div>
                      <v-checkbox
                        v-model="form.checkbox"
                        :rules="[ (v) => !!v || pageContent.content.form.agree]"
                        required
                        dark
                        class="d-inline-block"
                      >
                        <template v-slot:label>
                          <div class="text-white">
                            {{ pageContent.content.form.terms1 }}
                            <a
                              @click="sheet = !sheet"
                              class="
                                  text-white
                                  text-decoration-underline
                                "
                            >
                              {{ pageContent.content.form.terms2 }}
                            </a>
                          </div>
                        </template>
                      </v-checkbox>
                    </div>
                    <div class="my-2 text-white">
                      <v-btn
                        class="text-white bg-primary"
                        @click.prevent="step1check"
                        block
                      >
                        {{ pageContent.content.form.next }}
                      </v-btn>
                    </div>
                  </span>
                  <!-- BACK -->
                  <span class="d-block w-100">
                    <div class="my-2">
                      <v-btn
                        class="text-white text-initial align-items-center"
                        @click.prevent="$router.push(`/${language}/home`)"
                        text
                      >
                        <v-icon class="mr-3"> mdi-arrow-left</v-icon>
                        {{ pageContent.content.form.back }}
                      </v-btn>
                    </div>
                  </span>
                </v-form>
                <!-- /END STEP 1 -->

                <!-- STEP 2 -->
                <v-form
                  v-if="'step' in  pageContent.content && pageContent.content.step == 2"
                  ref="form2"
                  v-model="step2valid"
                  lazy-validation
                >
                  <!-- QUESTIONS -->
                  <span class="d-block w-100">
                    <label class="font-weight-bold text-white text-uppercase w-100 text-h6">
                      {{pageContent.content.form.question.label}}
                    </label>
                    <v-textarea
                      class="mt-0 pt-0"
                      filled
                      validate-on-blur
                      dark
                      counter
                      rows="4"
                      auto-grow
                      :placeholder="pageContent.content.form.question.placeholder"
                      v-model="form.question"
                      required
                      :rules="questionRules"
                    ></v-textarea>
                    <label class="font-weight-bold text-white text-uppercase w-100 text-h6">
                      {{pageContent.content.form.store.label}}
                    </label>
                    <v-radio-group
                      v-model="storeCountrySelected"
                      row
                    >
                     
                     <template v-for="(radio, index) in defaultTextContent.storeCountries.countries">

                      <v-radio
                        filled 
                        dark
                        
                        v-if="radio.status == 1"
                        class="ma-2"
                        :key="index"
                        :label="radio.name"
                        :value="radio.shortcode"
                      />
                     </template>
                     
                    </v-radio-group>
                    <v-autocomplete
                      filled
                      dark
                      validate-on-blur
                      v-if="stores != null"
                      v-model="form.selectStore"
                      :items="allStores"
                      :search-input.sync="search"
                      :item-text="getStoreText"
                      :rules="defaultRules"
                      :filter="searchStore"
                      :placeholder="pageContent.content.form.store.placeholder"
                      return-object
                      hide-no-data
                      required
                      chips
                      clearable
                      :menu-props="autocompleteMenuProps"
                      @change="form.checkboxNewsletter = false"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="remove(data.item)"
                          v-html="`<span class='d-inline-block text-truncate text-size-m' style='max-width: 200px;'><b class='mr-2'>${data.item.name}</b> ${data.item.city}</span>` "
                        >

                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-content class="py-2">
                          <v-row>
                            <v-col
                              cols="1"
                              v-if="data.item.online_store == '1'"
                            >
                              <v-icon small> mdi-web</v-icon>
                            </v-col>
                            <v-col
                              cols="1"
                              v-else
                            >
                              <v-icon small> mdi-store-marker</v-icon>
                            </v-col>
                            <v-col cols="10" class="body-2">
                              <v-list-item-title class="text-size-lg" v-html="data.item.name"></v-list-item-title>
                              <v-list-item-subtitle class="text-size-m" v-html="`${data.item.street} ${data.item.number}, ${data.item.postal} ${data.item.city} ${getStoreCountry(data.item.country_code)}` "></v-list-item-subtitle>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                     <!-- <p class="text-white">
                        {{this.form.checkboxNewsletter}}
                        </p> -->
                    <div v-if="form.selectStore != null && (form.selectStore.jb_store == 1 || form.selectStore.pme_store == 1) && language != 'be_fr'">
                     
                      <v-checkbox
                        v-model="form.checkboxNewsletter"
                        class="m-auto d-inline-block"
                        filled
                        dark
                      >
                        <template v-slot:label>
                          <div class="text-white">
                            {{ pageContent.content.form.newsletter }}
                          </div>
                        </template>
                      </v-checkbox>
                    </div>
                  </span>
                  <!-- NEXT -->
                  <span class="d-block w-100">
                    <div class="my-2">
                      <v-btn
                        class="text-white bg-primary"
                        @click.prevent="step2check"
                        block
                      >
                        {{ pageContent.content.form.next }}
                      </v-btn>
                    </div>
                  </span>
                  <!-- BACK -->
                  <span class="d-block w-100">
                    <div class="my-2">
                      <v-btn
                        class="text-white text-initial align-items-center"
                        @click.prevent="$router.push(`/${language}/steps/1`)"
                        text
                      >
                        <v-icon class="mr-3"> mdi-arrow-left</v-icon>
                        {{ pageContent.content.form.back }}
                      </v-btn>
                    </div>
                  </span>
                </v-form>
                <!-- /END STEP 2  -->

                <!-- STEP 3 -->
                <v-form
                  v-if="'step' in  pageContent.content && pageContent.content.step == 3"
                  ref="form3"
                  v-model="step3valid"
                  lazy-validation
                >
                  <!-- QUESTIONS -->
                  <span class="d-block w-100">

                    <label class="font-weight-bold text-white text-uppercase w-100 text-h6">
                      {{pageContent.content.form.size.tops.label}}
                    </label>
                    <v-select
                      dark
                      filled
                      validate-on-blur
                      v-model="form.size.top"
                      :items="pageContent.content.form.size.tops.options"
                      :placeholder="pageContent.content.form.size.tops.placeholder"
                      :rules="defaultRules"
                      required
                    ></v-select>

                    <label class="font-weight-bold text-white text-uppercase w-100 text-h6">
                      {{pageContent.content.form.size.bottoms.label}}
                    </label>
                    <div class="w-100 d-flex">

                      <v-select
                        dark
                        filled
                        validate-on-blur
                        v-model="form.size.bottomWaist"
                        :items="pageContent.content.form.size.bottoms.optionsWaist"
                        :placeholder="pageContent.content.form.size.bottoms.labelWaist"
                        :rules="defaultRules"
                        class="w-50"
                        required
                      ></v-select>
                      <v-select
                        dark
                        filled
                        validate-on-blur
                        v-model="form.size.bottomTop"
                        :items="pageContent.content.form.size.bottoms.optionsLeg"
                        :placeholder="pageContent.content.form.size.bottoms.labelLeg"
                        :rules="defaultRules"
                        class="w-50"
                        required
                      ></v-select>
                    </div>

                  </span>
                  <!-- NEXT -->
                  <span class="d-block w-100">
                    <div class="my-2">
                      <v-btn
                        class="text-white bg-primary"
                        @click.prevent="step3check"
                        block
                      >
                        {{ pageContent.content.form.next }}
                      </v-btn>
                    </div>
                  </span>
                  <!-- BACK -->
                  <span class="d-block w-100">
                    <div class="my-2">
                      <v-btn
                        class="text-white text-initial align-items-center"
                        @click.prevent="$router.push(`/${language}/steps/2`)"
                        text
                      >
                        <v-icon class="mr-3"> mdi-arrow-left</v-icon>
                        {{ pageContent.content.form.back }}
                      </v-btn>
                    </div>
                  </span>
                </v-form>
                <!-- /END STEP 3  -->

                <!-- STEP 4 -->
                <v-form
                  v-if="'step' in  pageContent.content && pageContent.content.step == 4"
                  ref="form4"
                  v-model="step3valid"
                  lazy-validation
                  style="max-width:100vw; overflow-hidden;"
                >
                  <!-- QUESTIONS -->
                  <span class="d-flex w-100 liRadioImage">

                    <v-radio-group
                      row
                      v-model="form.outfit"
                      v-if="'outfits' in  pageContent.content.form"
                      :rules="defaultRules"
                      required
                      mandatory
                    >
                      <template v-for="(outfit,index) in pageContent.content.form.outfits.options">
                        <v-radio
                          :value="outfit.value"
                          :key="index"
                          class="outfit"
                        >
                          <template v-slot:label>
                            <div class="label text-uppercase text-white text-h6">{{outfit.label}}</div>
                            <v-img
                              max-width="100%"
                              :src="outfit.image"
                            />
                          </template>
                        </v-radio>
                      </template>
                    </v-radio-group>

                  </span>
                  <!-- NEXT/BACK -->
                  <span class="d-flex w-10 pr-10">

                    <!-- BACK -->
                    <v-btn
                      class="text-white text-initial align-self-start d-none d-md-flex"
                      @click.prevent="$router.push(`/${language}/steps/3`)"
                      text
                      :block="isAppMobile"
                    >
                      <v-icon class="liTextShadow mr-3"> mdi-arrow-left</v-icon>
                      <span class="liTextShadow">
                        {{ pageContent.content.form.back }}
                      </span>
                    </v-btn>
                    <!-- NEXT -->
                    <v-btn
                      class="text-white bg-primary mx-auto px-10"
                      @click.prevent="validate"
                    >
                      {{ pageContent.content.form.next }}
                    </v-btn>
                    <v-btn
                      class="text-white text-initial align-self-end d-none d-md-flex"
                      text
                      disabled
                      style="cursor:none; opacity:0; visibility:0;"
                    >
                      <v-icon class="mr-3"> mdi-arrow-left</v-icon>
                      {{ pageContent.content.form.back }}
                    </v-btn>

                  </span>

                </v-form>
                <!-- /END STEP 4 -->

                <!-- </perfect-scrollbar> -->
              </v-sheet>
            </div>
          </v-sheet>

        </v-img>
      </v-row>
    </template>
    <!-- LOADING -->
    <template v-else>
      <div
        class="text-center w-100 d-flex"
        style="height: 50vh"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          class="m-auto"
        />
      </div>
    </template>
    <!-- ERRORS -->
    <template v-if="error != null">
      <v-overlay></v-overlay>
      <v-alert
        border="bottom"
        colored-border
        elevation="2"
        style="position:fixed; z-index:10; top:50%; left:50%; min-width 300px; width:50%; transform:translate(-50%,-50%)"
      >
        <v-row align="center">
          <v-col align="center">
            <h3 class="text-h6 mt-3 mb-4">
              {{checkTexts.defaultOpening}}
            </h3>
            <template v-for="(value, name, index) in error.Warnings">
              <v-chip
                class="ma-2 pt-3 pb-2"
                :key="index"
              >
                {{capitalizeFirstLetter(checkTexts[name].warning)}}
              </v-chip>
            </template>
            <p class="text-p mt-3">
              {{checkTexts.defaultClose}}
            </p>
            <v-btn
              class="bg-primary mb-4 px-10 text-white"
              @click.prevent="error = null"
            >
              {{checkTexts.buttonLabel}}
            </v-btn>
          </v-col>

        </v-row>

      </v-alert>
    </template>
    <!-- T&C's -->
    <template v-if="pageContent != null && 'form' in pageContent.content && 'privacyStatementTitle' in pageContent.content.form">
      <v-bottom-sheet v-model="sheet">
        <v-sheet
          class="text-left"
          height="80vh"
        >
          <div
            class="col-12 col-md-6 m-auto"
            style="position: relative"
          >
            <div class="text-h5 text-uppercase">
              {{ pageContent.content.form.privacyStatementTitle }}
              <v-btn
                @click="sheet = !sheet"
                color="primary"
                dark
                absolute
                top
                right
                fab
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
          <div
            class="col-12 col-md-6 m-auto overflow-y-auto"
            style="max-height: 65vh"
            v-html="privacyStatementContent"
          ></div>
          <div class="col-12 col-md-6 m-auto text-center">
            <v-btn
              color="primary"
              @click="sheet = !sheet"
            >
              {{ pageContent.content.form.closeTerms }}
            </v-btn>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </template>
  </v-container>
</template>
<script>
import _ from "lodash";
const API_URL = process.env.VUE_APP_CONTENT_API_URL;

import pme_icon from "../assets/images/icons/pme_icon.png";
import jb_icon from "../assets/images/icons/jb_icon.png";

export default {
  title: "Steps",
  name: "Steps",
  data() {
    return {
      allStores: [],
      pmeIcon: pme_icon,
      jbIcon: jb_icon,
      error: null,
      search: null,
      apiPublicUrl: API_URL,
      formLoading: null,
      sheet: false,
      step1valid: true,
      step1scroll: false,
      step2valid: true,
      step2scroll: false,
      step3valid: true,
      step3scroll: false,
      step4valid: true,
      step4scroll: false,
      defaultRules: [(v) => !!v || this.pageContent.content.form.required],
      questionRules: [
        (v) => !!v || this.pageContent.content.form.required,
        (v) =>
          v.length <= this.pageContent.content.form.question.length ||
          this.pageContent.content.form.question.required,
      ],
      dobRules: [
        (v) => !!v || this.pageContent.content.form.required,
        (v) => v.length == 10 || this.pageContent.content.form.dob.required,
      ],
      emailRules: [
        (v) => !!v || this.pageContent.content.form.required,
        (v) => /.+@.+\..+/.test(v) || this.pageContent.content.form.email.valid,
      ],
    };
  },
  created() {
    this.fetchURL();
  },
  watch: {
    page(val) {
      if (val.length == 0) {
        this.$router.push(`/${this.language}/404`);
      }
    },
    stores(val) {
      if (val) {
        let Stores;
        if (this.language == 'be_fr'){
          Stores =  val.filter((store) => store.country_code ==  'BE');
        } else { 
          Stores =  val.filter((store) => store.country_code ==  this.language.toUpperCase());
        }
        
        Stores = _.orderBy(
          Stores,
          ["online_store", "pme_store", "jb_store", "name"],
          ["desc", "desc", "desc", "asc"]
        );
        this.allStores = Stores;
      }
    },
    search(val) {
      if (this.stores != null) {
        val && val !== this.form.selectStore && this.queryStores(val);
      }
    },
    pageContent(val) {
      if (val != null) {
        this.$title = this.pageContent.default.pageTitle;
        if (
          "form" in this.pageContent.content &&
          this.privacyStatement == null
        ) {
          this.fetchAppPrivacyStatement();
        }
      }
    },
  },
  mounted() {
    if(this.dev){
      console.log("VIEW STEP:    ", this.step);
      console.log("Language:  ", this.language);
      console.log("Website:   ", this.website);
      console.log("FullUrl:   ", this.fullUrl);
      console.log("MOUNTED     √");
    }
    this.fetchPageData();
    this.fetchStores();
  },
  computed: {
    autocompleteMenuProps() {
      // default properties copied from the vuetify-autocomplete docs
      let defaultProps = {
        closeOnClick: false,
        closeOnContentClick: false,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 304
      };

      if (this.$vuetify.breakpoint.smAndDown) {
        defaultProps.maxHeight = 145;
        defaultProps.top = true;
      }
      return defaultProps;
    },
    storeCountrySelected:{
      get(){
        if(this.defaultTextContent != null){
          return this.defaultTextContent.storeCountries.default.toUpperCase();
        } else {
          return null;
        }

      },
      set(val){
         let Stores = this.stores.filter((store) => store.country_code == val);
        Stores = _.orderBy(
          Stores,
          ["online_store", "pme_store", "jb_store", "name"],
          ["desc", "desc", "desc", "asc"]
        );
        this.allStores = Stores;
        return val;
      }
    },
    checkTexts() {
      return this.defaultTextContent.checkTexts;
    },
    form: {
      get() {
        return this.$store.getters["liApiContent/getForm"];
      },
      set(val) {
        // console.log(val);
        this.$store.dispatch("liApiContent/SET_FORM", val);
      },
    },
    step() {
      return this.$route.params.step;
    },
    stores() {
      return this.$store.getters["liApiStore/stores"];
    },
    page() {
      // IF THE PAGE IS AN ACTUAL PAGE
      // return this.$store.getters["liApiContent/getPage"](this.fullUrl);
      // IF THE PAGE IS A STATIC BLOCK
      return this.$store.getters["liApiContent/getCollection"](
        `page_data_${this.language}_${this.fullUrl}`
      );
    },
    pageContent() {
      // IF THE PAGE IS AN ACTUAL PAGE
      // return this.page != null ? JSON.parse(this.page.content) : null;

      // IF THE PAGE IS A STATIC BLOCK
      let result = null;
      if (this.page != null && this.page.length != 0) {
        let block = JSON.parse(this.page[0].content);
        // console.log(block);
        if (block.type === "html") {
          result = JSON.parse(block.html);
        }
        // console.log(result);
      }
      return result;
    },
  },
  methods: {
    searchStore(item, queryText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >  -1 ||
        (!_.isEmpty(item.city) &&
        item.city
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
        )
        //   || 
        // (!_.isEmpty(item.street) &&
        // item.street
        //   .toLocaleLowerCase()
        //   .indexOf(queryText.toLocaleLowerCase()) > -1)
      );
    },
    onScroll() {
      this.step1scroll =
        this.$refs.scrollbar1.ps.scrollbarYTop > 20 ? true : false;
    },
    getStoreCountry(val) {
      if (!_.isEmpty(val)) {
        let country = this.defaultTextContent.languageSelector.languages.find(
          (item) => {
            if (item.shortcode == val.toLowerCase()) return true;
          }
        );
        return !_.isEmpty(country) ? country.name : val;
      } else {
        return val;
      }
    },
    getStoreText(val) {
      return `${val.name}, ${val.city}`;
    },
    queryStores(v) {
      if (this.stores != null) {
        return this.allStores.filter((store) => {
          if (store.name.toLowerCase().includes(v.toLowerCase())) return true;
        });
      }
    },
    fetchStores() {
      if (this.strores == null) {
        this.$store.dispatch("liApiStore/GET_STORES");
      }
    },
    step1check() {
      this.$refs.form1.validate();
      this.formLoading = true;
      setTimeout(() => {
        if (this.step1valid) {
          this.formLoading = false;
          this.$router.push(`/${this.language}/steps/2`);
        } else {
          this.formLoading = false;
        }
      }, 1000);
    },
    step2check() {
      this.$refs.form2.validate();
      this.formLoading = true;
      setTimeout(() => {
        if (this.step2valid) {
          this.formLoading = false;
          this.$router.push(`/${this.language}/steps/3`);
        } else {
          this.formLoading = false;
        }
      }, 1000);
    },
    step3check() {
      this.$refs.form3.validate();
      this.formLoading = true;
      setTimeout(() => {
        if (this.step3valid) {
          this.formLoading = false;
          this.$router.push(`/${this.language}/steps/4`);
        } else {
          this.formLoading = false;
        }
      }, 1000);
    },
    validate() {
      this.$refs.form4.validate();
      this.formLoading = true;
      setTimeout(() => {
        if (this.step4valid) {
          const checkArray = this.defaultTextContent.checkArray;
          let status = this.checkIfObjecFilledValues(checkArray, this.form);
          if (status.Total == 0) {
            this.error = null;
            const applicantName = encodeURI(
              `${this.form.firstName.trim()} ${this.form.lastName.trim()}`
            );
            const _numbers = new Date().getTime();
            this.form.applicantName = applicantName;
            this.form.timestamp = _numbers;
            // this.form.fileName = `https://stores.justbrands.eu/fylwith_pmelegend_2022/${_numbers}_${decodeURI(applicantName).replace(/[^a-z0-9]/gi, "_").toLowerCase()}.jpeg`;
            // this.form.fileNameMobile = `https://stores.justbrands.eu/fylwith_pmelegend_2022/${_numbers}_${decodeURI(applicantName).replace(/[^a-z0-9]/gi, "_").toLowerCase()}_mobile.jpeg`;
            this.form.fileName = `${_numbers}_${decodeURI(applicantName)
              .replace(/[^a-z0-9]/gi, "_")
              .toLowerCase()}.jpeg`;
            this.form.fileNameMobile = `${_numbers}_${decodeURI(applicantName)
              .replace(/[^a-z0-9]/gi, "_")
              .toLowerCase()}_mobile.jpeg`;
            this.form.fileNamePlain = `${_numbers}_${decodeURI(applicantName)
              .replace(/[^a-z0-9]/gi, "_")
              .toLowerCase()}`;
              
            let _data = {
              language: this.language,
              firstName: this.form.firstName.trim(),
              lastName: this.form.lastName.trim(),
              email: this.form.email.trim(),
              question: this.form.question.trim(),
              dob: this.form.dob.trim(),
              outfit: this.form.outfit,
              address: {
                street: this.form.address.streetUser.trim(),
                number: this.form.address.numberUser.trim(),
                addition: this.form.address.additionUser.trim(),
                postal: this.form.address.zipUser.trim(),
                city: this.form.address.cityUser.trim(),
                country: this.form.address.countryUser.trim(),
              },
              fileName: this.form.fileName,
              fileNameMobile: this.form.fileNameMobile,
              size: {
                top: this.form.size.top.trim(),
                bottom: `${this.form.size.bottomWaist.trim()} - ${this.form.size.bottomTop.trim()}`,
              },
              store: {
                name: this.form.selectStore.name,
                street: this.form.selectStore.street,
                number: this.form.selectStore.number,
                postal: this.form.selectStore.postal,
                city: this.form.selectStore.city,
                country: this.form.selectStore.country,
                jb_store: this.form.selectStore.jb_store,
                pme_store: this.form.selectStore.pme_store,
                srs_shop_id: this.form.selectStore.srs_shop_id,
              },
              newsletter: this.form.checkboxNewsletter,
              phone: this.form.phone,
            };
            this.$store
              .dispatch("liApiStore/POST_FORM", _data)
              .then((response) => {
                if (response) {
                  this.$router.push(`/${this.language}/share/${applicantName}`);
                }
              });
          } else {
            this.formLoading = false;
            this.error = status;
          }
        } else {
          this.formLoading = false;
        }
      }, 1000);
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>
<style lang="scss">
.liForm {
  .ps {
    height: calc(100vh - 300px);
  }
}
</style>