<template>
  <v-container v-if="pageContent != null" class="w-100 pa-0" :class="`${pageContent.content.styleClasses}`" fluid :style="`  padding-top:${isAppMobile ? '55px' : isAppTablet ? '65px' : '75px'}!important;`">
    <!-- CONTENT -->
    <template>
      <template v-for="(block, index) in pageContent.content.blocks">
        <component :is="`liBlock_${block.type}`" :block="block" :key="`${block.identifier}_${index}`" :index="index" />
      </template>
    </template>
  </v-container>
</template>
<script>
export default {
  title: "",
  name: "Story",
  data() {
    return {};
  },
  created() {
    this.fetchURL();
  },
  watch: {
    page(val) {
      if (val.length == 0) {
        this.$router.push(`/${this.language}/404`);
      }
    },
    pageContent(val) {
      if (val != null) {
        this.$title = this.pageContent.default.pageTitle;
      }
    },
  },
  mounted() {
    this.fetchPageData();
  },
  computed: {
    page() {
      // IF THE PAGE IS AN ACTUAL PAGE
      // return this.$store.getters["liApiContent/getPage"](this.fullUrl);
      // IF THE PAGE IS A STATIC BLOCK
      return this.$store.getters["liApiContent/getCollection"](`page_data_${this.language}_${this.fullUrl}`);
    },
    pageContent() {
      // IF THE PAGE IS AN ACTUAL PAGE
      // return this.page != null ? JSON.parse(this.page.content) : null;

      // IF THE PAGE IS A STATIC BLOCK
      let result = null;
      if (this.page != null && this.page.length != 0) {
        let block = JSON.parse(this.page[0].content);
        // console.log(block);
        if (block.type === "html") {
          result = JSON.parse(block.html);
        }
      }
      return result;
    },
  },
  methods: {},
};
</script>
